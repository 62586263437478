import React, {useEffect, useState} from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import App from './App';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react"
import ColorBinderPrivacyPage from './ColorBinderPrivacyPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FilterGardernPrivacyPage from "./FilterGardernPrivacyPage";


ReactModal.setAppElement('#root');


function Overlay() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    // Function to open project modal and close hamburger menu
    const openProjectModal = () => {
        setIsProjectModalOpen(true);
        setIsMenuOpen(false); // Close hamburger menu
    };

    // Function to open contact modal and close hamburger menu
    const openContactModal = () => {
        setIsContactModalOpen(true);
        setIsMenuOpen(false); // Close hamburger menu
    };

    // Function to open privacy policy modal and close hamburger menu
    const openPrivacyModal = () => {
        setIsPrivacyModalOpen(true);
        setIsMenuOpen(false); // Close hamburger menu
    };

    const projects = [
        { name: "🌷 Filter Garden", url: "https://www.filtergarden.com" },
        { name: "📒 Color Binder", url: "https://www.colorbinder.com" },
        { name: "🤖 Character 3D", url: "https://www.character3d.ai" },
        { name: "🍿 Flix Find", url: "https://www.flixfind.com" },
        { name: "🖍️ Doodle Walls", url: "https://www.doodlewalls.com" },
    ];

    const privacy_policy = [
        { name: "📒 Color Binder", url: "https://www.colorbinder.com" }
    ];


    // Function to handle the click event
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log("Menu toggled. New state:", !isMenuOpen); // Debugging line
    };

    // Function to close the menu on window resize
    const closeMenuOnResize = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    // Set up the event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', closeMenuOnResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', closeMenuOnResize);
        };
    }, [isMenuOpen]);

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none'}}>
            {/* Hamburger Menu */}
            <div className="hamburger-menu"  onClick={toggleMenu} style={{ position: 'absolute', top: 20, left: 20, cursor: 'pointer', zIndex: 1, pointerEvents: 'all', }}>
                <div className="hamburger-line" style={{ width: '30px', height: '3px', backgroundColor: 'black', margin: '5px 0' }}></div>
                <div className="hamburger-line" style={{ width: '30px', height: '3px', backgroundColor: 'black', margin: '5px 0' }}></div>
                <div className="hamburger-line" style={{ width: '30px', height: '3px', backgroundColor: 'black', margin: '5px 0' }}></div>
            </div>

            <ReactModal
                isOpen={isProjectModalOpen}
                onRequestClose={() => setIsProjectModalOpen(false)}
                contentLabel="Projects Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div>
                    <center><h2  className="modal-header">Projects</h2></center>
                    <button onClick={() => setIsProjectModalOpen(false)} className="modal-close-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"
                             fill="none">
                            <path
                                d="M9 9L11.9999 11.9999M11.9999 11.9999L14.9999 14.9999M11.9999 11.9999L9 14.9999M11.9999 11.9999L14.9999 9M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <ul className="project-list">
                    {projects.map((project, index) => (
                        <li key={index} className="project-item">
                            <a href={project.url} className="project-link" target="_blank" rel="noopener noreferrer">
                                {project.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </ReactModal>


            <ReactModal
                isOpen={isPrivacyModalOpen}
                onRequestClose={() => setIsPrivacyModalOpen(false)}
                contentLabel="Privacy Policy Modal"
                className="modal-content-privacy"
                overlayClassName="modal-overlay"
            >
                <div>
                    <center><h2 className="modal-header">Privacy Policy</h2></center>
                    <button onClick={() => setIsPrivacyModalOpen(false)} className="modal-close-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"
                             fill="none">
                            <path
                                d="M9 9L11.9999 11.9999M11.9999 11.9999L14.9999 14.9999M11.9999 11.9999L9 14.9999M11.9999 11.9999L14.9999 9M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <ul className="project-list">
                    {/* Individual entries for each privacy policy */}
                    <li className="project-item">
                        <Link to="/privacy-policy/color-binder" className="project-link">
                            📒 Color Binder
                        </Link>
                    </li>
                    <li className={"project-item"}>
                        <Link to="/privacy-policy/filter-garden" className="project-link">
                            🌷 Filter Garden
                        </Link>
                    </li>
                </ul>
            </ReactModal>

            <ReactModal
                isOpen={isContactModalOpen}
                onRequestClose={() => setIsContactModalOpen(false)}
                contentLabel="Contact Modal"
                className="modal-content-contact"
                overlayClassName="modal-overlay"
            >
                <div>
                    <center><h2 className="modal-header">Contact</h2></center>
                    <button onClick={() => setIsContactModalOpen(false)} className="modal-close-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"
                             fill="none">
                            <path
                                d="M9 9L11.9999 11.9999M11.9999 11.9999L14.9999 14.9999M11.9999 11.9999L9 14.9999M11.9999 11.9999L14.9999 9M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <div className="contact-content">
                        <p>Feel free to email me at:</p>
                        <a href="mailto:mail.hdhillon@gmail.com" className="contact-email-link">
                            mail.hdhillon@gmail.com
                        </a>
                        <a href="mailto:contact@cubedigitaltechnologies.com" className="contact-email-link">
                            contact@cubedigitaltechnologies.com
                        </a>
                    </div>


                </div>
            </ReactModal>

            {/* Menu Items */}
            {isMenuOpen && (
                <div className="menu-items" style={{
                    position: 'absolute',
                    top: 60,
                    left: 20,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    pointerEvents: 'all'
                }}>
                    <div onClick={openProjectModal}
                         style={{display: 'block', margin: '10px 0', cursor: 'pointer'}} className="overlay-text-item">Projects
                    </div>
                    <div onClick={openContactModal}
                         style={{display: 'block', margin: '10px 0', cursor: 'pointer'}} className="overlay-text-item">Contact
                    </div>
                    <div onClick={openPrivacyModal}
                         style={{display: 'block', margin: '10px 0', cursor: 'pointer'}} className="overlay-text-item">Privacy Policy
                    </div>
                </div>
            )}

            {/* Static Overlay Text */}
            <div className="overlay-text" id="cube_title"
                 style={{position: 'absolute', bottom: 40, left: 40, pointerEvents: 'all'}}>
                🔲 Cube Digital Technologies
            </div>
            <div className="overlay-text" style={{
                position: 'absolute',
                top: 40,
                left: 40,
                fontFamily: 'EarlyGameBoy',
                lineHeight: '1.6em',
                whiteSpace: 'pre',
                pointerEvents: 'all'
            }}>
                <div onClick={() => setIsProjectModalOpen(true)} className="overlay-text-item">Projects</div>
                <br/>
                <div onClick={() => setIsContactModalOpen(true)} className="overlay-text-item">Contact</div>
            </div>
            <div className="overlay-text"
                 style={{position: 'absolute', bottom: 40, right: 40, pointerEvents: 'all'}}>
                <div onClick={() => setIsPrivacyModalOpen(true)} className="overlay-text-item">Privacy Policy</div>
            </div>
        </div>
    );
}

function MainLayout({ children }) {
    return (
        <>
            <App/>
            <Overlay/>
            <Analytics />
        </>
    );
}

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={< MainLayout/>} />
                <Route path="/privacy-policy/color-binder" element={<ColorBinderPrivacyPage />} />
                <Route path="/privacy-policy/filter-garden" element={<FilterGardernPrivacyPage />} />
            </Routes>
        </Router>
    </React.StrictMode>
);