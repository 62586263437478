import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, Stage, OrbitControls, Environment } from '@react-three/drei'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { easing } from 'maath'

export default function App() {
    return (
        <Canvas className="myCanvas" gl={{ logarithmicDepthBuffer: true }} shadows camera={{ position: [-15, 0, 10], fov: 25 }}>      <fog attach="fog" args={['black', 15, 21.5]} />
            <Stage intensity={0.5} environment="city" shadows={{ type: 'accumulative', bias: -0.001 }} adjustCamera={false}>
                <Kamdo rotation={[0, Math.PI, 0]} />
            </Stage>
            <OrbitControls autoRotate autoRotateSpeed={0.05} enableZoom={false} makeDefault minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
            <EffectComposer disableNormalPass>
                <Bloom luminanceThreshold={1} mipmapBlur />
            </EffectComposer>
            <Environment background preset="sunset" blur={0.8} />
        </Canvas>
    )
}

function Kamdo(props) {
    const head = useRef()
    const stripe = useRef()
    const light = useRef()
    const { nodes, materials } = useGLTF('/s2wt_kamdo_industrial_divinities-transformed.glb')
    useFrame((state, delta) => {
        const t = (1 + Math.sin(state.clock.elapsedTime * 2)) / 2
        stripe.current.color.setRGB(1 + t * 10, 2, 20 + t * 50)
        easing.dampE(head.current.rotation, [0, state.pointer.x * (state.camera.position.z > 1 ? 1 : -1), 0], 0.4, delta)
        light.current.intensity = 1 + t * 2
    })
    return (
        <group {...props}>
            <mesh castShadow receiveShadow geometry={nodes.body001.geometry} material={materials.Body} />
            <group ref={head}>
                <mesh castShadow receiveShadow geometry={nodes.head001.geometry} material={materials.Head} />
                <mesh castShadow receiveShadow geometry={nodes.stripe001.geometry}>
                    <meshBasicMaterial ref={stripe} toneMapped={false} />
                    <pointLight ref={light} intensity={1} color={[10, 2, 5]} distance={2.5} />
                </mesh>
            </group>
        </group>
    )
}

useGLTF.preload('/s2wt_kamdo_industrial_divinities-transformed.glb')
